import PropTypes from 'prop-types';
import { FloatButton } from 'antd';
import { ExportOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Tooltip } from '@mui/material';
import axios from 'axios';
import { getToken } from '../utils/api/handlers';
// eslint-disable-next-line import/no-extraneous-dependencies

export default function ExportToPDF({ url = null, children, saveName }) {
  const printDocument = () => {
    window.print();
    // const input = document.getElementsByClassName('divToPrint');
    // console.log(input);
    // if (input.length > 0) {
    //   const opt = {
    //     margin: 1,
    //     filename: 'myfile.pdf',
    //     image: { type: 'jpeg', quality: 0.98 },
    //     html2canvas: { scale: 2 },
    //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    //   };
    //
    //   html2pdf().from(input[0]).set(opt).save();
    // }
  };

  const handleDownload = async (urls) => {
    try {
      const response = await axios.get(urls, {
        responseType: 'blob', // Important for binary data
        headers: {
          Authorization: `Token ${getToken()}`
        }
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', saveName); // File name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <div>
      {children}

      <FloatButton.Group trigger="click" type="primary" style={{ insetInlineEnd: 24 }} icon={<ExportOutlined />}>
        <Tooltip title="PDF">
          <FloatButton onClick={printDocument} />
        </Tooltip>
        {url && (
          <Tooltip title="Excel">
            <FloatButton icon={<FileExcelOutlined />} onClick={() => handleDownload(url)} />
          </Tooltip>
        )}
      </FloatButton.Group>
    </div>
  );
}

ExportToPDF.propTypes = {
  url: PropTypes.any,
  saveName: PropTypes.any,
  children: PropTypes.node
};
