import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { IconButton, MenuItem } from '@mui/material';
import StyledMenu from '../../../ui-component/StyledMenu';
import { useState } from 'react';
import { IconDiscount, IconDots, IconEye } from '@tabler/icons-react';
import { DeleteOutline } from '@mui/icons-material';
import { TableListSkeleton } from '../../../ui-component/Skeletons';
import PerformDiscount from '../PerformDiscount';
import InvoiceDetails from '../InvoiceDetails';
import { INVOICES_STATUS } from '../../../utils/libs/constants';
import PropTypes from 'prop-types';
import CancelInvoiceDialog from './CancelInvoiceDialog';
import PermissionProvider from '../../users-roles-manager/components/PermissionHandler';
import { PERMISSIONS } from '../../../utils/libs/permissions';

function InvoiceTable({
  pageLoading = true,
  next = null,
  columns = [],
  invoices = [],
  count = 0,
  setInvoices,
  fetchInvoices,
  onNextPage = (nextPage) => {}
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowInvoice, setRowInvoice] = useState(null);
  const [openPerformDiscountModal, setOpenPerformDiscountModal] = useState(false);
  const [openInvoiceDetailsModal, setOpenInvoiceDetailsModal] = useState(false);
  const [openCancelInvoiceDialog, setOpenCancelInvoiceDialog] = useState(false);

  const open = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onNextPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (event, invoice) => {
    setRowInvoice(invoice);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setRowInvoice(null);
  };

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableListSkeleton isLoading={pageLoading}>
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === 'actions') {
                        return (
                          <TableCell align="left">
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'demo-customized-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              disableElevation
                              aria-haspopup="true"
                              onClick={(e) => handleClick(e, row)}
                            >
                              <IconDots />
                            </IconButton>
                            <StyledMenu
                              id="demo-customized-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                'aria-labelledby': 'demo-customized-menu'
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  setOpenInvoiceDetailsModal(true);
                                  // navigateToEditCustomer(rowCustomer.id);
                                }}
                              >
                                <IconEye style={{ fontSize: 25, color: 'green', marginRight: 10 }} />
                                View
                              </MenuItem>
                              {rowInvoice !== null && rowInvoice.status === INVOICES_STATUS.PENDING && (
                                <MenuItem
                                  onClick={() => {
                                    setOpenPerformDiscountModal(true);
                                  }}
                                >
                                  <IconDiscount style={{ fontSize: 25, color: 'green', marginRight: 10 }} />
                                  Perform Discount
                                </MenuItem>
                              )}
                              {rowInvoice !== null &&
                                ![INVOICES_STATUS.CANCELLED, INVOICES_STATUS.COMPLETED_PAYMENTS, INVOICES_STATUS.ONPROGRESS].includes(
                                  rowInvoice.status
                                ) && (
                                  <PermissionProvider permission={PERMISSIONS.INVOICES_DELETE}>
                                    <MenuItem
                                      onClick={() => {
                                        setOpenCancelInvoiceDialog(true);
                                      }}
                                    >
                                      <DeleteOutline style={{ fontSize: 25, color: 'red' }} />
                                      Cancel
                                    </MenuItem>
                                  </PermissionProvider>
                                )}
                            </StyledMenu>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TableListSkeleton>
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{
            disabled: pageLoading || !next
          }}
          backIconButtonProps={{
            disabled: pageLoading || page === 0
          }}
        />
      </Paper>
      <PerformDiscount open={openPerformDiscountModal} setOpen={setOpenPerformDiscountModal} invoice={rowInvoice} />
      {openInvoiceDetailsModal && (
        <InvoiceDetails
          open={openInvoiceDetailsModal}
          onClose={() => {
            setOpenInvoiceDetailsModal(false);
            handleClose();
            fetchInvoices();
          }}
          setInvoice={setRowInvoice}
          invoice={rowInvoice}
        />
      )}

      {openCancelInvoiceDialog && (
        <CancelInvoiceDialog
          open={openCancelInvoiceDialog}
          onClose={(bill) => {
            setOpenCancelInvoiceDialog(false);
            handleClose();
            if (bill) {
              setInvoices((prevState) => prevState.map((i) => (i.id.toString() === rowInvoice.id.toString() ? bill : i)));
            }
          }}
          invoice={rowInvoice}
        />
      )}
    </>
  );
}

InvoiceTable.propTypes = {
  onNextPage: PropTypes.func,
  setInvoices: PropTypes.func,
  fetchInvoices: PropTypes.func,
  count: PropTypes.number,
  next: PropTypes.any,
  invoices: PropTypes.array,
  columns: PropTypes.array,
  pageLoading: PropTypes.bool
};

export default InvoiceTable;
