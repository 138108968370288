import MainCard from '../../../ui-component/cards/MainCard';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InvoicePaymentsTable from './InvoicePaymentsTable';
import usePayments from '../../../utils/libs/hooks/use-payments';
import { currencyFormatter, renderDateTime, renderPaymentStatus, renderPaymentType } from '../../../utils/libs';
import PropTypes from 'prop-types';

export default function CustomerInvoicePayments({ invoiceID = '0', setInvoice }) {
  // const { customers, pageLoading, next, count } = useCustomers(true);
  const { pageLoading, payments, setPayments, next } = usePayments(true, { invoiceID });
  const { t } = useTranslation();
  const columns = [
    { id: 'created_at_', label: 'Created At' },
    { id: 'payment_reference', label: 'Payment Reference No' },
    { id: 'amount', label: 'Amount' },
    { id: 'paid_by_name', label: 'Recorded By' },
    { id: 'payment_type_', label: 'Payment Type' },
    { id: 'status_', label: t('common.status') },
    { id: 'actions', label: t('common.actions') }
  ];
  return (
    <MainCard
      title={
        <Grid container spacing={3} alignItems="center">
          <Grid item>List of Invoice Payments</Grid>
        </Grid>
      }
    >
      <InvoicePaymentsTable
        pageLoading={pageLoading}
        next={null}
        columns={columns}
        count={0}
        setPayments={setPayments}
        setInvoice={setInvoice}
        payments={payments.map((i) => ({
          ...i,
          status_: renderPaymentStatus(i.status),
          created_at_: renderDateTime(i.created_at),
          amount: currencyFormatter(parseFloat(i.amount), i.currency),
          payment_type_: renderPaymentType(i.payment_type)
        }))}
      />
    </MainCard>
  );
}

CustomerInvoicePayments.propTypes = {
  invoiceID: PropTypes.any,
  setInvoice: PropTypes.func
};
