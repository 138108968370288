import { listPaymentsRequest, searchPaymentsRequest } from '../../api';
import { useEffect, useState } from 'react';
import appNotify from '../appNotify';

export default function usePayments(init = false, initialsParams = {}) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [next, setNext] = useState(null);
  const [params, setParams] = useState({
    page: null,
    search: null,
    ...initialsParams
  });
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [paymentOptionsLoading, setPaymentOptionsLoading] = useState(false);
  function listPayments() {
    setPageLoading(true);
    listPaymentsRequest(params)
      .then((res) => {
        setPayments(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to load payments');
      });
  }

  function loadPayments() {
    setPaymentOptionsLoading(true);
    searchPaymentsRequest(params)
      .then((res) => {
        setPaymentOptions(res.results);
        setPaymentOptionsLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Error in loading invoice payments');
        setPaymentOptions([]);
      });
  }

  useEffect(() => {
    if (init) listPayments();
    else if (params.search && params.search.length > 0) {
      loadPayments();
    }
  }, [params]);

  return { pageLoading, next, payments, count, paymentOptions, setParams, paymentOptionsLoading, setPayments };
}
