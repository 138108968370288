import { Button, DialogContent, DialogTitle, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import { IconCash, IconEye, IconListDetails, IconChevronLeft, IconDiscount, IconListCheck } from '@tabler/icons-react';
import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'react-i18next';
import PerformDiscountForm from './components/PerformDiscountForm';
import DiscountDetails from './components/DiscountDetails';
import { Transition } from '../../ui-component';
import CustomerInvoicePayments from './components/CustomerInvoicePayments';
import MainCard from '../../ui-component/cards/MainCard';
import { addDiscountRequest } from '../../utils/api';
import ViewInvoice from './ViewInvoice';
import AddPayment from './AddPayment';
import appNotify from '../../utils/libs/appNotify';
import { INVOICES_STATUS } from '../../utils/libs/constants';

function InvoiceDetails({ open, onClose, onAddDiscount = () => {}, invoice = null, setInvoice }) {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();
  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  function handleClose() {
    onClose();
  }

  useEffect(() => {
    console.log('Inv: ', invoice.status, invoice.status !== INVOICES_STATUS.COMPLETED_PAYMENTS);
  }, []);

  function addDiscount(values) {
    setLoading(true);
    addDiscountRequest({ ...values, bill: invoice.id })
      .then((res) => {
        console.log('Res: ', res);
        appNotify.success('Discount added successfully');
        setTab(3);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to add discount');
        setLoading(false);
      });
  }

  return (
    <Dialog TransitionComponent={Transition} fullScreen fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle style={{ fontWeight: 'bold', fontSize: 20 }}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item>
            <Grid container>
              <Grid item>
                <Button
                  startIcon={<IconChevronLeft />}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Go Back
                </Button>
              </Grid>
              {invoice !== null && <Grid item>Invoice No: #{invoice.id}</Grid>}
            </Grid>
          </Grid>
          <Grid item>
            <Tabs value={tab} onChange={handleChange} centered>
              <Tab value={0} icon={<IconEye />} iconPosition="start" label="Invoice Details" />
              <Tab value={1} icon={<IconListCheck />} iconPosition="start" label="List Payments" />
              {![INVOICES_STATUS.COMPLETED_PAYMENTS, INVOICES_STATUS.CANCELLED].includes(invoice.status) && (
                <Tab value={2} icon={<IconCash />} iconPosition="start" label="Add Payment" />
              )}
              <Tab value={3} icon={<IconListDetails />} iconPosition="start" label="List Discounts" />
              {invoice.status === INVOICES_STATUS.PENDING && (
                <Tab value={4} icon={<IconDiscount />} iconPosition="start" label="Add Discount" />
              )}
            </Tabs>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center">
          {invoice && (
            <Grid item xs={11}>
              {tab === 0 && <ViewInvoice invoiceID={invoice.id} />}
              {tab === 1 && <CustomerInvoicePayments invoiceID={invoice.id} setInvoice={setInvoice} />}
              {tab === 2 && <AddPayment invoice={invoice} setTab={setTab} setInvoice={setInvoice} />}
              {tab === 3 && <DiscountDetails invoice={invoice} />}
              {tab === 4 && (
                <MainCard
                  title={
                    <Grid container spacing={3} alignItems="center">
                      <Grid item>Adding Discount</Grid>
                    </Grid>
                  }
                >
                  <PerformDiscountForm currentAmount={invoice.amount} onSubmit={(values) => addDiscount(values)} loading={loading} />
                </MainCard>
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default InvoiceDetails;
