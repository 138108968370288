import MainCard from '../../../../ui-component/cards/MainCard';
import { Grid } from '@mui/material';
import InvoiceTable from '../../../invoices/components/invoiceTable';
import useInvoices from '../../../../utils/libs/hooks/use-invoices';
import { currencyFormatter, renderDateTime, renderInvoicesStatus } from '../../../../utils/libs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function CustomerInvoices({ customerId }) {
  const { invoices, listInvoices, setInvoices, pageLoading, count, next } = useInvoices(true, { customer: customerId });
  const { t } = useTranslation();
  const columns = [
    { id: 'created_at', label: t('common.created_at') },
    { id: 'invoice_no', label: t('invoices.invoice_no') },
    { id: 'balance_', label: 'Balance' },
    { id: 'paid_amount', label: t('invoices.paid_amount') },
    { id: 'status_', label: t('common.status') },
    { id: 'actions', label: t('common.actions') }
  ];
  return (
    <MainCard
      title={
        <Grid container spacing={3} alignItems="center">
          <Grid item>{t('invoices.list_of_invoices')}</Grid>
        </Grid>
      }
    >
      <InvoiceTable
        next={next}
        columns={columns}
        pageLoading={pageLoading}
        count={count}
        fetchInvoices={listInvoices}
        setInvoices={setInvoices}
        invoices={invoices.map((i) => ({
          ...i,
          created_at: renderDateTime(i.created_at),
          status_: renderInvoicesStatus(i.status),
          invoice_no: `#${i.id}`,
          balance_: currencyFormatter(i.balance, 'USD'),
          paid_amount: currencyFormatter(i.paid_amount, 'USD')
        }))}
      />
    </MainCard>
  );
}
CustomerInvoices.propTypes = {
  customerId: PropTypes.any
};
export default CustomerInvoices;
