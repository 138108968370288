import axios from 'axios';
import Config from '../Config';
import { logoutAction } from '../libs';
import appNotify from '../libs/appNotify';

export function getToken() {
  try {
    const token = JSON.parse(localStorage.getItem('token'));
    return token !== null ? token : '';
  } catch (e) {
    return '';
  }
}

export const requestResponseHandler = (error, authenticated, isLogoutRequest) => {
  if (!error.response) {
    appNotify.error('No network available, Please connect to a Network');
  } else if (error.response.status === 401) {
    appNotify.error('Unauthorized request, please log in again');
    // snack.error('Unauthorized request, please login again');
    logoutAction();
  } else if (error.response.status === 400) {
    // Bad request
  } else if (error.response.status === 500) {
    // 500 ERROR PAGE
  } else if (error.response && error.response.status === 429) {
    console.log('Status: ', 429);
  } else {
    console.log(`Error occurred, status: ${error.response.status}`);
  }
};

export function performPostRequest(method, payload, options = {}) {
  const { authenticated = true, isLogoutRequest = false, apiURL = Config.apiURL } = options;
  return new Promise((resolve, reject) => {
    axios
      .post(apiURL + method, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        requestResponseHandler(error, authenticated, isLogoutRequest);
        reject(error);
      });
  });
}

export function performGetRequest(method, options = {}) {
  return new Promise((resolve, reject) => {
    const { authenticated = true, isLogoutRequest = false, apiURL = Config.apiURL } = options;
    axios
      .get(apiURL + method, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        requestResponseHandler(error, authenticated, isLogoutRequest);
        reject(error);
      });
  });
}

export function performPutRequest(method, payload, options = {}) {
  const { authenticated = true, isLogoutRequest = false, apiURL = Config.apiURL } = options;

  return new Promise((resolve, reject) => {
    axios
      .put(apiURL + method, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        requestResponseHandler(error, authenticated, isLogoutRequest);
        reject(error);
      });
  });
}

export function performDeleteRequest(method, options = {}) {
  const { authenticated = true, isLogoutRequest = false, apiURL = Config.apiURL } = options;

  return new Promise((resolve, reject) => {
    axios
      .delete(apiURL + method)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        requestResponseHandler(error, authenticated, isLogoutRequest);
        reject(error);
      });
  });
}

export function performAuthenticatedGetRequest(method, params = {}, options = {}) {
  const { token = `Token ${getToken()}`, authenticated = true, isLogoutRequest = false, apiURL = Config.apiURL } = options;
  return new Promise((resolve, reject) => {
    axios
      .get(apiURL + method, {
        headers: {
          Authorization: token
        },
        params
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        requestResponseHandler(error, authenticated, isLogoutRequest);
        reject(error);
      });
  });
}

export function performAuthenticatedPutRequest(method, payload, options = {}) {
  const { authenticated = true, isLogoutRequest = false, apiURL = Config.apiURL } = options;
  console.log('Here: ', method, payload, options);
  return new Promise((resolve, reject) => {
    axios
      .put(apiURL + method, payload, {
        headers: {
          Authorization: `Token ${getToken()}`
        }
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        requestResponseHandler(error, authenticated, isLogoutRequest);
        reject(error);
      });
  });
}

export function performAuthenticatedPostRequest(method, payload, options = {}) {
  const { authenticated = true, isLogoutRequest = false, apiURL = Config.apiURL } = options;
  const token = `Token ${getToken()}`;
  return new Promise((resolve, reject) => {
    axios
      .post(apiURL + method, payload, {
        headers: {
          Authorization: token
        }
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        requestResponseHandler(error, authenticated, isLogoutRequest);
        reject(error);
      });
  });
}

export function performAuthenticatedDeleteRequest(method, options = {}, payload = {}) {
  const { authenticated = true, isLogoutRequest = false, apiURL = Config.apiURL } = options;

  return new Promise((resolve, reject) => {
    axios
      .delete(apiURL + method, {
        params: payload,
        headers: {
          Authorization: `Token ${getToken()}`
        }
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        requestResponseHandler(error, authenticated, isLogoutRequest);
        reject(error);
      });
  });
}
