import { useState } from 'react';
import { addPaymentRequest } from '../../utils/api';
import appNotify from '../../utils/libs/appNotify';
import AddPaymentForm from './components/AddPaymentForm';
import PropTypes from 'prop-types';

function AddPayment({ invoice, setTab, setInvoice }) {
  const [loading, setLoading] = useState(false);

  function addPayment(values) {
    setLoading(true);
    addPaymentRequest(values)
      .then((res) => {
        appNotify.success('Payment recorded successfully');
        setTab(1);
        setInvoice(res.bill);
        setLoading(false);
      })
      .catch(() => {
        appNotify.error('Failed to record payments');
        setLoading(false);
      });
  }
  return <AddPaymentForm invoice={invoice} loading={loading} onSubmit={(values) => addPayment(values)} />;
}

AddPayment.propTypes = {
  invoice: PropTypes.any,
  setTab: PropTypes.func,
  setInvoice: PropTypes.func
};

export default AddPayment;
