import { DialogContent, DialogTitle, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { INVOICE_CANCELLATION_REASONS } from '../../../utils/libs/constants';
import AnimateButton from '../../../ui-component/extended/AnimateButton';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { cancelInvoiceRequest } from '../../../utils/api';
import appNotify from '../../../utils/libs/appNotify';
import PermissionProvider from '../../users-roles-manager/components/PermissionHandler';
import { PERMISSIONS } from '../../../utils/libs/permissions';

function CancelInvoiceDialog({ open, onClose, invoice = null }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  function cancelInvoice(values) {
    setLoading(true);
    cancelInvoiceRequest(invoice.id, values)
      .then((res) => {
        if (res.status) {
          appNotify.success('Invoice cancelled successfully');
          onClose(res.bill);
        } else {
          appNotify.error(res.message);
        }
      })
      .catch(() => {
        appNotify.error('Error occurred in cancelling invoice, please contact IT');
      })
      .finally(() => setLoading(false));
  }

  return (
    <PermissionProvider permission={PERMISSIONS.INVOICES_DELETE} showUnAuthorizedPage>
      <Dialog fullWidth maxWidth="md" open={open} onClose={() => onClose()}>
        <DialogTitle style={{ fontWeight: 'bold', fontSize: 20 }}>
          Cancel Invoice - #{(invoice ?? {}).id} {(invoice ?? {}).customer_name}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              reason: null
            }}
            validationSchema={Yup.object().shape({
              reason: Yup.string().max(255).required('Please provide reason for cancellation')
            })}
            onSubmit={(values) => {
              cancelInvoice(values);
            }}
          >
            {({ errors, handleChange, handleSubmit, touched, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={24}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="bootstrap">Reason</InputLabel>
                      <Select
                        labelId="reason"
                        id="reason"
                        value={values.unit}
                        name="reason"
                        placeholder="Provide reason of the invoice cancellation"
                        onChange={handleChange}
                        style={{
                          height: 50,
                          borderRadius: 30
                        }}
                        error={Boolean(touched.reason && errors.reason)}
                      >
                        {Object.entries(INVOICE_CANCELLATION_REASONS).map(([_, value]) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.reason && errors.reason && (
                        <FormHelperText error id="standard-weight-helper-text-email-login">
                          {errors.reason}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" mg={3}>
                  <Grid item xs={6}>
                    <AnimateButton>
                      <LoadingButton
                        disableElevation
                        loading={loading}
                        disabled={loading}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {t('common.submit')}
                      </LoadingButton>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </PermissionProvider>
  );
}

export default CancelInvoiceDialog;

CancelInvoiceDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  invoice: PropTypes.any
};
